<template>
  <div class="container-label">
    <div v-if="!loading" class="page">
      <div class="px-4 py-4" style="position: relative;">
        <div class="d-flex justify-content-between mb-4">
          <div class="text-left mb-2">
            <div class="logo">
              <img
                :src="$store.state.app?.logo"
                class="mr-2"
                alt="Clean UI"
              />
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-between mb-4">
          <div class="text-left mb-2 w-50">
            <div class="text-dark mb-1 title-text"> DITERBITKAN ATAS NAMA </div>
            <div class="d-flex">
              <div class="w-25 desc-text-1">Penjual</div>
              <div class="desc-text-1 mr-1">:</div>
              <div class="w-75 desc-text-2 ml-2" v-if="!orderDetail.dropshipper">{{ sellerDetail && sellerDetail.name || '' }}</div>
              <div class="w-75 desc-text-2 ml-2" v-else>{{ `${orderDetail.dropshipper.name}, ${orderDetail.dropshipper.phone}` }}</div>
            </div>
          </div>

          <div class="text-left mb-2 w-50">
            <div class="text-dark mb-1 title-text"> UNTUK </div>
            <div class="d-flex mb-1">
              <div class="destination-title desc-text-1 mr-3">Pembeli</div>
              <div class="desc-text-1 mr-1">:</div>
              <div class="destination-desc desc-text-2">{{ getFullName }}</div>
            </div>
            <div class="d-flex mb-1">
              <div class="destination-title desc-text-1 mr-3">No Order</div>
              <div class="desc-text-1 mr-1">:</div>
              <div class="destination-desc desc-text-2">{{ invoiceDetail && invoiceDetail.order_number }}</div>
            </div>
            <div class="d-flex mb-1">
              <div class="destination-title desc-text-1 mr-3">Tanggal Order</div>
              <div class="desc-text-1 mr-1">:</div>
              <div class="destination-desc desc-text-2">{{ getOrderDate }}</div>
            </div>
            <div class="d-flex mb-1">
              <div class="destination-title desc-text-1 mr-3">Alamat</div>
              <div class="desc-text-1 mr-1">:</div>
              <div class="destination-desc desc-text-2">{{ getAddress }}</div>
            </div>
            <div class="d-flex mb-1">
              <div class="destination-title desc-text-1 mr-3">No Telepon</div>
              <div class="desc-text-1 mr-1">:</div>
              <div class="destination-desc desc-text-2">{{ getPhoneNumber }}</div>
            </div>
          </div>
        </div>

        <div class="d-flex align-items-center" v-if="invoiceDetail">
          <table style="width:100%;">
            <tr class="text-left title-text" style="border-bottom: 2px solid #000000; border-top: 2px solid #000000; line-height: 50px">
              <th class="header-product"> PRODUK </th>
              <th class="header-qty"> JUMLAH </th>
              <th class="header-price"> HARGA SATUAN </th>
              <th class="header-total"> TOTAL HARGA </th>
            </tr>
            <tr class="text-left tr-product-list" style=" border-bottom: 1px solid #F4F4F4;" v-for="(item, index) in invoiceDetail && invoiceDetail.invoice_items" :key="index">
              <td class="desc-text-blue header-product" style="vertical-align: top;"> {{ item.catalog_title || '' }} </td>
              <td class="desc-text-3 header-qty" style="vertical-align: top;"> {{ item.quantity || 0  }} ({{ item.unit || '' }}) </td>
              <td class="desc-text-3 header-price" style="vertical-align: top;">
                <div class="desc-product-discount-text" :style="item && item.promotions && item.promotions.discounts.length > 0 && 'text-decoration: line-through;'">Rp. {{ toCurrency(item.selling_price) }}</div>
                <div class="desc-product-discount-text d-flex justify-content-between mt-1" style="gap: 0px 10px;" v-for="(obj, idx) in item && item.promotions && item.promotions.discounts" :key="idx">
                  <div class="disc-per-product-tag">{{ renderElementTagDiscountPerProduct(obj) }}</div>
                  <div class="disc-per-product-value">Rp. {{renderElementValueDiscountPerProduct(idx, item)}}</div>
                </div>
              </td>
              <td class="desc-text-blue header-total" style="vertical-align: top;">Rp. {{ toCurrency(item.sub_total) }}</td>
            </tr>
          </table>
        </div>

        <div class="d-flex">
          <div style="width: 35%"></div>
          <div class="mt-3 mb-3" style="width: 65%">
            <div class="d-flex justify-content-between price-border-bottom">
              <div class="text-left price-text-title">TOTAL HARGA ({{ invoiceDetail && invoiceDetail.invoice_items.length || 0 }} PRODUK)</div>
              <div class="text-right price-text-title" style="width: 140px">Rp. {{ toCurrency(invoiceDetail && invoiceDetail.sub_total) }}</div>
            </div>
            <div class="d-flex justify-content-between price-border-bottom" v-for="({ name, promotion_value, gift_value, value_type }, index) in discountTransactionList" :key="index">
              <div class="text-left price-text-2">{{ name }}{{ value_type.toUpperCase() === "PERCENTAGE" ? ` (${gift_value}%)` : '' }}</div>
              <div class="text-right price-text-2" style="width: 140px">Rp. {{ toCurrency(promotion_value) }}</div>
            </div>
            <div class="d-flex justify-content-between price-border-bottom" v-for="({ name, promotion_value, gift_value, value_type }, index) in voucherTransactionList" :key="index">
              <div class="text-left price-text-2">{{ name }}{{ value_type.toUpperCase() === "PERCENTAGE" ? ` (${gift_value}%)` : '' }}</div>
              <div class="text-right price-text-2" style="width: 140px">Rp. {{ toCurrency(promotion_value) }}</div>
            </div>
            <div class="d-flex justify-content-between price-border-bottom" v-if="orderDetail && orderDetail.loyalty_usage">
              <div class="text-left price-text-1">Koin</div>
              <div class="text-right price-text-2" style="width: 140px">{{ toCurrency(orderDetail.loyalty_usage) }}</div>
            </div>
            <div class="d-flex justify-content-between price-border-bottom" v-if="orderDetail && orderDetail.payment_data && orderDetail.payment_data.credit_limit_usage">
              <div class="text-left price-text-1">Credit Limit</div>
              <div class="text-right price-text-2" style="width: 140px">-Rp. {{ toCurrency(orderDetail.payment_data.credit_limit_usage) }}</div>
            </div>
            <div class="d-flex justify-content-between price-border-bottom" v-if="invoiceDetail && invoiceDetail.deposit">
              <div class="text-left price-text-1">Deposit Retur</div>
              <div class="text-right price-text-2" style="width: 140px">- {{ toCurrency(invoiceDetail.deposit) }}</div>
            </div>
            <div class="d-flex justify-content-between price-border-bottom" v-if="deliveryCost > 0">
              <div class="text-left w-50" style="font-size: 13px">Biaya Pengiriman</div>
              <div class="text-right w-50" style="font-size: 13px">Rp. {{ toCurrency(deliveryCost) }}</div>
            </div>
            <div class="d-flex justify-content-between" style="lineHeight: 35px;">
              <div class="text-left price-text-title">TOTAL TAGIHAN</div>
              <div class="text-right desc-text-orange" style="width: 140px">Rp. {{ toCurrency(orderDetail && (orderDetail.gross_total - (orderDetail.payment_data.credit_limit_usage ?? 0))) }}</div>
            </div>
          </div>
        </div>
        <a-divider/>
        <div class="d-flex">
          <div class="text-left mt-3 mb-0 w-50">
            <div class="mb-1 desc-text-1">Kurir</div>
            <div class="d-flex">
              <div class="w-75 desc-text-2">
                <span v-if="orderDetail?.shipment_data?.courier !== 'OWN'">{{ orderDetail?.shipment_data?.courier }} - </span>
                {{ orderDetail?.shipment_data?.service_name }}
              </div>
            </div>
          </div>
          <div class="text-left mt-3 mb-0">
            <div class="mb-1 desc-text-1">Metode Pembayaran</div>
            <div class="d-flex">
              <div class="w-75 desc-text-2">{{ paymentName }}</div>
            </div>
          </div>
        </div>

        <div class="text-left mt-4 mb-4 w-50">
          <div class="text-dark mb-1 desc-text-1">Invoice ini sah dan diproses oleh komputer. Silahkan hubungi <span class="desc-text-blue">Customer Service Sarung Indonesia</span> apabila Anda membutuhkan bantuan.</div>
        </div>

        <div class="invoice-stamp">
          <InvoicePaidIcon v-if="orderDetail && orderDetail.payment_state === 'PAID'"/>
          <InvoiceUnpaidIcon v-else/>
        </div>
      </div>
      <div class="page-breaker" />

    </div>
    <div v-else class="loader-page d-flex justify-content-center align-items-center">
      <a-icon type="loading" class="loader-icon"/>
    </div>
  </div>
</template>

<script>
import InvoicePaidIcon from '@/components/Icons/InvoicePaid.vue'
import InvoiceUnpaidIcon from '@/components/Icons/InvoiceUnpaid.vue'
import { mapState } from 'vuex'
import moment from 'moment'

export default {
  name: 'print.label',
  components: {
    InvoicePaidIcon,
    InvoiceUnpaidIcon,
  },
  data: () => ({
    orderDetail: null,
    invoiceDetail: null,
    sellerDetail: null,
    paymentName: '',
    discountTransactionList: null,
    discountPerProductList: null,
    voucherTransactionList: null,
    defaultDiscountPrice: 0,
    loading: false,
    deliveryCost: 0,
  }),
  computed: {
    ...mapState(['account', 'storeSetting']),
    ...mapState('user', {
      store_url: state => state.store_url,
    }),
    user_address () {
      return this.account && this.account.profile && this.account.profile?.address_data
    },
    user_data() {
      return this.account && this.account.profile && this.account.profile?.user_data
    },
    user_store_data() {
      return this.storeSetting && this.storeSetting.storeProfile && this.storeSetting.storeProfile.store_data
    },
    getOrderDate() {
      const orderDate = this.invoiceDetail ? this.invoiceDetail.created_date : null
      return orderDate ? moment(this.invoiceDetail.created_date).format('DD - MMMM - YYYY') : ''
    },
    getAddress() {
      const invoiceAddress = this.invoiceDetail ? this.invoiceDetail.address : null
      const addressBilling = invoiceAddress ? `${invoiceAddress.address_line1}, ${invoiceAddress.district}, ${invoiceAddress.country}, ${invoiceAddress.postal_code}` : ''
      return addressBilling
    },
    getPhoneNumber() {
      const billingPhone = this.invoiceDetail ? this.invoiceDetail.billing.phone.slice(0, -3) + 'XXX' : ''
      return billingPhone
    },
    getFullName() {
      const billingName = this.invoiceDetail ? this.invoiceDetail.billing.name : ''
      return billingName
    },
  },
  methods: {
    fetchInvoiceDetail() {
      this.loading = true
      const urlSearchParams = new URLSearchParams(window.location.search)
      const params = Object.fromEntries(urlSearchParams.entries())
      if (params) {
        this.$store.dispatch('purchase/GETINVOICEDETAILNOAUTH', {
          order_id: params.id,
          token: params.token,
        })
          .then(({ data }) => {
            this.invoiceDetail = data
            this.discountTransactionList = data?.promotions?.discounts ?? []
            this.voucherTransactionList = data?.promotions?.vouchers_used ?? []
            this.discountPerProductList = data?.invoice_items ?? []
            this.$store.dispatch('purchase/GETDETAILORDERNOAUTH', {
              order_id: params.id,
              channel_id: this.$store.state.app.channel_id,
              token: params.token,
            })
              .then(({ data }) => {
                this.orderDetail = data
                this.deliveryCost = (data?.shipment_data?.shipping_cost?.value + data?.shipment_data?.insurance_cost?.value) || 0
                this.$store.dispatch('warehouse/GETWAREHOUSELIST', {
                  id: [data.warehouse_id],
                })
                  .then(({ data }) => {
                    this.sellerDetail = data[0] ?? null
                    this.loading = false
                  })
              })
              .catch(err => {
                this.loading = false
                console.log(err)
              })
          })
          .catch(err => {
            this.loading = false
            console.log(err)
          })
      } else {
        this.loading = false
        this.$notification.error({
          message: 'Mising query',
          description: 'Url lacks query params',
        })
      }
    },
    getPaymentDetail() {
      this.loading = true
      const urlSearchParams = new URLSearchParams(window.location.search)
      const params = Object.fromEntries(urlSearchParams.entries())
      this.$store.dispatch('purchase/GETDETAILPAYMENTNOAUTH', {
        order_id: params.id,
        channel_id: this.$store.state.app.channel_id,
        token: params.token,
      })
        .then(({ data }) => {
          this.paymentName = data && data.payment_name ? data.payment_name : '-'
          this.loading = false
        })
        .catch(err => {
          this.loading = false
          console.log(err)
        })
    },
    toCurrency(value) {
      return `${Intl.NumberFormat('en-US').format(value || 0)}`
    },
    renderElementTagDiscountPerProduct(obj) {
      if (obj.value_type.toUpperCase() === 'PERCENTAGE') {
        return `${obj.gift_value}%`
      } else {
        return `Rp. ${this.toCurrency(obj.gift_value)}`
      }
    },
    renderElementValueDiscountPerProduct(index, dataListItem) {
      let sellingPrice = dataListItem?.selling_price
      /* eslint-disable no-unused-expressions */
      dataListItem?.promotions?.discounts.map((obj, idx) => {
        if (idx <= index) {
          sellingPrice = sellingPrice - (obj?.promotion_value / dataListItem?.quantity)
        }
      })
      return `Rp. ${this.toCurrency(sellingPrice)}`
    },
  },
  created () {
    this.fetchInvoiceDetail()
    this.getPaymentDetail()
  },
}
</script>

<style lang="scss">

.container-label {
  width: 100%;
  background-color: white;

  .header {
    width: 100%;
    padding: .75rem;
  }

  .page {
    text-align: center;
    margin-top: 2rem;

    .content {
      width: 50%;
      border: 1px solid black;
      // margin-top: 1rem;
      margin: auto;
    }

    .page-breaker {
      // position: relative;
      // text-align: center;
      page-break-before: always;
      // margin-bottom: 20px;
    }
  }

  .btn-primary {
    color: white;
    border: 1px solid #015289;
    background-color: #015CA1;
    border-radius: 8px;
  }
  .logo {
      display: flex;
      flex-direction: row;
      align-items: center;
      white-space: nowrap;
      img {
        height: 55px;
        object-fit: cover;
      }
    }
}

@media print {
  .container-label {
    .header {
      > div {
        display: none;

      }
    }
    .page {
      .content {
        width: 70%;
      }
    }
  }
}

.title-text {
  font-family: Open Sans;
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  color: #000000;
}

.desc-text-1 {
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  color: #767676;
}

.desc-text-2 {
  font-family: Open Sans;
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  color: #000000;
}

.desc-text-3 {
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  color: #000000;
}

.desc-product-discount-text {
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  color: #000000;
  line-height: 16px;
}

.desc-text-blue {
  font-family: Roboto;
  font-size: 13px;
  font-weight: 500;
  color: #6cc8eb;
}

.desc-text-orange {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  color: #F5831F;
}

.price-text-title {
  font-family: Roboto;
  font-size: 13px;
  font-weight: 600;
  color: #000000;
}

.price-text-1 {
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  color: #767676;
}

.price-text-2 {
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  color: #000000;
}

.price-border-bottom {
  line-height: 35px;
  border-bottom: 1px solid #F4F4F4;
}

.invoice-stamp {
  position: absolute;
  top: 20%;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 0;
  opacity: 0.9;
}

.tr-product-list td {
  padding: 20px 0px;
}

.disc-per-product-tag {
  background-color: #FFF2F2;
  border-radius: 2px;
  padding: 3px;
  font-family: Open Sans;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #FF0000;
}

.disc-per-product-value {
  padding: 3px;
  font-family: Open Sans;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
}

.loader-page {
  height: 100vh;
  .loader-icon {
    background-color: black;
    opacity: 0.2;
    padding: 10px;
  }
}
.header-product {
  padding-right: 15px;
  width: 40%;
}
.header-qty {
  padding-left: 15px;
  padding-right: 15px!important;
  width: 10%;
  text-align: right;
}
.header-price {
  padding-left: 15px;
  width: 30%;
  text-align: right;
}
.header-total {
  padding-left: 15px;
  width: 20%;
  text-align: right;
}
</style>
